var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.njangi_groups.length)+") Njangi Groups")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.njangi_groups,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin)+" ")]}},{key:"item.njangi_contact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.njangi_contact)+" ")]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }