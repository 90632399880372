<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ njangi_groups.length }}) Njangi Groups</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="njangi_groups"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
      </template>
      <template #[`item.admin`]="{item}">
        {{ item.admin }}
      </template>
      <!-- status -->
      <template #[`item.njangi_contact`]="{item}">
        {{ item.njangi_contact }}
      </template>

      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      switch1: true,
      headers: [
        { text: 'Njangi Name', value: 'name' },
        { text: 'Admin', value: 'admin' },
        { text: 'Contact', value: 'njangi_contact' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      njangi_groups: [],
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Njangi | List of Groups"
      this.getNjangiGroups()
  },

  methods:{
    async getNjangiGroups(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/njangi/get/groups/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.njangi_groups = response.data  // get the data and fill into campaigns
          console.log(this.njangi_groups)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
